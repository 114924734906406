.range-input::-webkit-slider-runnable-track {
  height: 2px; /* Thin range bar */
  background: #d1d5db; /* Light gray color */;
}

.range-input::-moz-range-track {
  height: 2px; /* Thin range bar */
  background: #d1d5db; /* Light gray color */
}

.range-input::-ms-track {
  height: 2px; /* Thin range bar */
  background: #d1d5db; /* Light gray color */
  border-color: transparent;
  color: transparent;
}

.range-input::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  background-color: black; /* Black thumb */
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  margin-top: -7px; /* Align the thumb with the track */
}

.range-input::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background-color: black; /* Black thumb */
  border-radius: 50%;
  cursor: pointer;
}

.range-input::-ms-thumb {
  width: 16px;
  height: 16px;
  background-color: black; /* Black thumb */
  border-radius: 50%;
  cursor: pointer;
}