/* Full-screen container for loading view */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
    color: white;
    font-family: Arial, sans-serif;
  }
  
  /* Spinner animation */
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid rgba(255, 255, 255, 0.2);
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  /* Keyframes for spinner rotation */
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Animated loading text */
  .loading-text {
    font-size: 1.2rem;
    animation: fadeText 1.5s ease-in-out infinite;
    color: white;
  }
  
  /* Text fade animation */
  @keyframes fadeText {
    0%, 100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
  